.about-page {
  width: 90%;
  margin: 0 auto;
  margin-top: 5rem;
}

.about-page .slider {
  display: flex;
  width: 100%;
  height: 300px;
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.about-page .slider img {
  width: auto;
  height: 100%;
  flex-shrink: 0;
  object-fit: cover;
}

.about-page .bio-container {
  width: 700px;
  margin: 0 auto;
  color: #f9f6e4;
}

.about-page .bio-container h1 {
  font-size: xxx-large;
  color: #f9f6e4;
}

.about-page .bio-container .about-contact p {
  margin-bottom: 0;
}

.about-page .bio-container .about-contact a {
  color: #ead13a;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: bold;
}

.about-page .bio-container .about-contact a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .about-page .bio-container {
    width: 90%;
  }
}
