.videos-page {
  margin: 0 auto;
  margin-top: 2rem;
}

.videos-page .videos-container h1 {
  color: #f9f6e4;
  text-align: center;
  font-size: xxx-large;
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.videos-page .videos-container .videos {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  padding-bottom: 5rem;
}

.videos-page .videos-container .videos .main-video {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
}

.videos-page .videos-container .videos .main-video iframe {
  width: 80vw;
  height: 90vh;
  border: none;
  border-radius: 10px;
}

.videos-page .videos-container .videos .side-videos iframe {
  width: 40vw;
  height: 50vh;
  border: none;
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .videos-page .videos-container .videos .main-video {
    width: 90%;
    border-bottom: 1px solid #fff;
    padding-bottom: 2rem;
  }

  .videos-page .videos-container .videos .main-video iframe {
    width: 100%;
  }

  .videos-page .videos-container .videos .side-videos iframe {
    width: 100%;
  }
}
