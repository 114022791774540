.booking-page {
  width: 700px;
  margin: 0 auto;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .booking-page {
    width: 90%;
  }
}
