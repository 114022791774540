.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 3.4375rem;
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  z-index: 2;
}

.header.normal {
  background-color: transparent;
}

.header .nav {
  display: flex;
  gap: 30px;
  align-items: center;
  margin-left: -1rem;
}

.header .nav a {
  text-decoration: none;
  color: #f9f6e4;
  text-transform: uppercase;
  border-bottom: 1px solid transparent;
  height: fit-content;
  transition: all 0.3s ease-in-out;
  text-align: center;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.02rem;
  text-transform: uppercase;
}

.header .nav a:hover {
  border-bottom: 1px solid #f9f6e4;
}

.header .links {
  display: flex;
  gap: 50px;
  height: 100%;
  width: auto;
}

.header .links a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .links img {
  position: absolute;
}

.header .links .gradient {
  position: absolute;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  border-radius: 100%;
}

.header .links .gradient:hover {
  opacity: 1;
}

.header .dropdown {
  margin-top: 1.25rem;
}

.header .dropdown-content {
  position: absolute;
  left: 0;
  width: 20vw;
  pointer-events: none;
  opacity: 0;
  margin-top: -1rem;
  transition: all 0.3s ease;
  background-color: #000;
  display: flex;
  flex-direction: column;
}

.header .dropdown nav {
  padding: 1rem;
  padding-left: 0;
  padding-bottom: 0;
  border-bottom: 1px solid #f9f6e4;
}

.header .dropdown nav a {
  color: #f9f6e4;
  text-decoration: none;
  border-bottom: 1px solid #f9f6e4;
  width: 100%;
  text-transform: uppercase;
  font-size: 24px;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
}

.header .dropdown nav a:nth-child(1) {
  border-top: 1px solid #f9f6e4;
}

.header .dropdown nav a:last-child {
  border: none;
}

.header .dropdown img {
  cursor: pointer;
}

.header .logo-container {
  display: flex;
  margin-left: -14rem;
  align-items: center;
}

.header .logo {
  width: 100%;
  max-width: 235px;
  height: 30px;
  z-index: 1;
  transform: scale(1);
}

.header .logo.gradient {
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  background-color: transparent;
}

.header .logo.gradient:hover {
  opacity: 1;
}

.header a {
  transition: all 0.5s ease;
}

.header .dropdown-content.show {
  pointer-events: all;
  opacity: 1;
  margin-top: 0;
}

@media only screen and (max-width: 768px) {
  .header {
    position: sticky;
    justify-content: space-between;
    width: 100vw;
    background-color: #000;
    padding-bottom: 2rem;
    margin-top: 0;
  }

  .header .links {
    margin-right: 3.5rem;
  }

  .header .dropdown {
    margin-left: 1.25rem;
  }

  .header .dropdown-content {
    width: 100vw;
  }

  .header .dropdown-content.show {
    pointer-events: all;
    opacity: 1;
    margin-top: 0;
  }

  .header .logo-container {
    margin-left: 0;
    transform: scale(0.7);
  }

  .header .logo {
    height: 20px;
  }

  .header .logo.gradient {
    display: none;
  }
}
