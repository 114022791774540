.shows-table {
    margin-bottom: 5rem;
}

.shows-table .description {
    color: #F9F6E4;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.shows-table .description h1 {
    margin-bottom: 0;
    font-size: xxx-large;
    font-weight: 900;
}

.shows-table .description p {
    margin-top: 0;
    margin-bottom: 2rem;
    max-width: 390px;
    min-width: 360px;
    color: #F9F6E4;
    text-align: center;
}

.shows-table table {
    border-collapse: separate;
    border-spacing: 0;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    color: #F9F6E4;
    width: 100%;
}

.shows-table table thead tr th {
    text-align: left;
}

.shows-table table tbody tr {
    text-align: left;
}

.shows-table table tbody tr a {
    color: #F9F6E4;
    border: 1px solid #F9F6E4;
    padding: 0.85rem 1.75rem;
    background-color: transparent;
    border-radius: 10px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    text-decoration: none;
    font-weight: 400;
}

.shows-table table tbody tr a:hover {
    color: #000;
    background-color: #F9F6E4;
}
  
.shows-table table th,
.shows-table table td {
    border: 1px solid #F9F6E4;
    border-right: none;
    border-left: none;
    border-top: none;
    padding: 8px;
}
  
.accordion {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}
  
.accordion-item {
    border-bottom: 1px solid #F9F6E4;
    margin-bottom: 10px;
    overflow: hidden;
}
  
.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding-bottom: 0.5rem;
    color: #F9F6E4;
    cursor: pointer;
}

.accordion-header .city {
    font-size: xx-large;
    font-weight: 400;
}
  
.accordion-content {
    background-color: transparent;
    color: #F9F6E4;
    max-height: 0;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
}

.accordion-content.active {
    max-height: 200px;
    opacity: 1;
}

.accordion-content a {
    color: #F9F6E4;
}
  
.accordion-content p {
    color: #F9F6E4;
}

.accordion-icon {
    font-size: 3em;
    font-weight: lighter;
}

.accordion-icon.minus {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}

.accordion-icon.plus {
    transform: rotate(0);
    transition: transform 0.3s ease-in-out;
}
    