.hero {
  width: 95%;
  height: 100%;
  display: flex;
  overflow-y: hidden;
  margin: 0 auto;
  margin-top: 4rem;
}

.hero .background-image {
  object-fit: cover;
  width: 100%;
  height: fit-content;
}

.hero .logo {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  z-index: 1;
  transition: all 0.5s ease;
}

.hero .logo.gradient {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  background-color: transparent;
}

.hero .logo.gradient:hover {
  opacity: 1;
}

.hero a {
  position: relative;
  transition: all 0.5s ease;
}

.hero .logo.scrolling {
  top: -60px;
  transform: scale(0.2);
}

@media only screen and (max-width: 768px) {
  .hero {
    width: 80vw;
    margin-top: 0;
  }

  .hero .background-image {
    height: 90vh;
    border-radius: 10px;
    margin-top: 3rem;
  }

  .hero .logo {
    width: 85% !important;
    transform: translateX(30px) translateY(30px);
    transition: all 0.5s ease;
  }

  .hero .logo.hide {
    opacity: 0;
    pointer-events: none;
  }

  .hero a.hide {
    opacity: 0;
    pointer-events: none;
    margin-top: -1rem;
  }

  .hero a.show {
    opacity: 1;
    pointer-events: all;
    margin-top: 0;
  }
}
