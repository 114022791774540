.footer {
  margin-top: 5rem;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.footer a {
  position: relative;
  color: #f9f6e4;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.2em;
  border-bottom: 1px solid transparent;
}

.footer a:hover {
  border-bottom: 1px solid #f9f6e4;
}

.footer .footer-background {
  position: absolute;
  z-index: -1;
  width: 100%;
}

.footer .footer-items {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
}

.footer .footer-navigation ul {
  display: flex;
  justify-content: space-around;
  padding-left: 0;
  width: 300px;
}

.footer .footer-links ul {
  display: flex;
  justify-content: space-around;
  padding-left: 4rem;
}

.footer .footer-navigation,
.footer .footer-links {
  position: relative;
  z-index: 1;
}

.footer .footer-logo {
  margin-bottom: -6rem;
}

.footer .footer-logo img {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .footer {
    text-align: center;
  }

  .footer .footer-items {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .footer .footer-items .footer-navigation ul {
    flex-direction: column;
    width: auto;
    margin-bottom: 0;
  }

  .footer .footer-items .footer-links ul {
    padding-left: 0;
    margin-top: 0;
  }

  .footer .footer-background {
    object-fit: contain;
    width: 100%;
    z-index: 0;
  }
}
