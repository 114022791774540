.posts {
  width: 70vw;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 5rem;
}

.posts article {
  display: flex;
  margin-bottom: 4rem;
  gap: 50px;
}

.posts article h1 {
  font-size: 2em;
  margin-bottom: 0;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  width: fit-content;
}

.posts article h1:hover {
  border-bottom: 1px solid #f9f6e4;
}

.posts article h3 {
  margin-top: 0;
  font-weight: normal;
}

.posts article p {
  margin-bottom: 2rem;
}

.posts article img {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

.posts article .content {
  width: 100%;
  color: #f9f6e4;
}

.posts article .content a {
  color: #f9f6e4;
  text-decoration: none;
}

.posts article .content a.cta {
  border: 1px solid #f9f6e4;
  padding: 0.85rem 1.75rem;
  background-color: transparent;
  color: #f9f6e4;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.posts article .content a.cta:hover {
  color: #000;
  background-color: #f9f6e4;
}

@media only screen and (max-width: 768px) {
  .posts {
    width: 80vw;
  }

  .posts article {
    flex-direction: column;
  }

  .posts article img {
    min-width: 250px;
    min-height: 250px;
    max-width: 300px;
    max-height: 300px;
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    margin: 0 auto;
  }

  .posts article .content a > h1 {
    margin-top: 0;
  }

  .posts article .content h3 {
    margin-top: 0;
  }
}
