@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body,
html {
  background-color: #000;
  height: fit-content;
  width: 100vw;
  margin: 0 auto;
}

* {
  font-family: "Montserrat", sans-serif;
}

*::-webkit-scrollbar {
  display: none;
}

ul {
  list-style: none;
}

p {
  font-weight: 200;
}

@media only screen and (max-width: 768px) {
  body,
  html {
    overflow-x: clip;
  }
}
