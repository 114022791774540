.tickets-page {
  width: 90%;
  margin: 0 auto;
  margin-top: 5rem;
}

@media only screen and (max-width: 768px) {
  .tickets-page .shows-table {
    width: 90%;
    margin: 0 auto;
  }
}
